import AlertBox from '@components/features/AlertBox';
import Attraction from '@components/features/Attraction/Attraction';
import NavBar from '@components/features/NavBar';
import useFetchInformation from '@hooks/useFetchInformation';
import { AttractionType, RegionType, StateType } from '@utils/types';
import { useEffect, useState } from 'react';

const AttractionsPage = () => {
    const { information } = useFetchInformation();
    const [attractions, setAttractions] = useState<AttractionType[]>([]);
    const [states, setStates] = useState<StateType[]>([]);
    const [regions, setRegions] = useState<RegionType[]>([]);

    useEffect(() => {
        if (!information) return;

        setAttractions(information.attractions);
        setStates(information.states);
        setRegions(information.regions);
    }, [information]);

    return (
        <section className="overflow-hidden text-gray-700 pt-12">
            <NavBar />
            <div className="container px-5 py-5 mx-auto lg:py-12 lg:px-32">
                <div className="flex flex-wrap -m-1 md:-m-2">
                    {information
                        ? attractions.length > 1 ?
                            attractions.map((attraction: AttractionType) => (
                                <Attraction
                                    attraction={attraction}
                                    states={states}
                                    regions={regions}
                                    key={attraction.id}
                                />
                            ))
                            : <AlertBox
                                color='orange'
                                message="Er zijn geen attracties gevonden." />
                        : <AlertBox
                            color='red'
                            message='Er is iets mis gegaan bij het ophalen van de informatie. Probeer het later opnieuw.'
                        />
                    }
                </div>
            </div>
        </section>
    );
};

export default AttractionsPage;