import { AttractionType, RegionType, StateType } from '@utils/types';
import Label from '../Label';

type Props = {
    attraction: AttractionType;
    states: StateType[];
    regions: RegionType[];
};

const Attraction = ({ attraction, states, regions }: Props) => {
    const getStateByStateId = (stateId: StateType['id']) => {
        return states?.find((state: StateType) => state.id === stateId);
    };

    const getRegionByRegionId = (regionId: RegionType['id']) => {
        return regions?.find((region: RegionType) => region.id === regionId);
    };

    return (
        <div className="flex flex-wrap w-full md:w-1/3" title={attraction?.name}>
            <div className="relative w-full m-2 md:m-3 cursor-pointer">
                <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                    src={attraction?.cover} />
                <div className="absolute top-0 right-0 w-full h-full opacity-50 rounded-lg" style={{ backgroundColor: getStateByStateId(attraction.status_id)?.color }}></div>
                <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed md:opacity-0 opacity-50 hover:opacity-100 transition duration-300 ease-in-out flex flex-col items-center justify-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <h1 className='text-white font-bold uppercase text-1xl md:text-3xl max-w-full px-5 truncate'>{attraction?.name}</h1>
                    <h1 className='text-white text-md md:text-1xl uppercase italic'>{getRegionByRegionId(attraction.region_id)?.name}</h1>
                </div>
                <Label
                    className="absolute top-3 left-3"
                    color={getStateByStateId(attraction.status_id)?.color}
                    name={getStateByStateId(attraction.status_id)?.name}
                />
            </div>
        </div>
    );
};

export default Attraction;