import { StateType } from '@utils/types';

type Props = {
    className?: string;
    color: StateType['color'];
    name: StateType['name'];
};

const Label = ({ className, color, name }: Props) => (
    <span className={`md:inline-block hidden text-md py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded ${className}`} style={{ backgroundColor: color }}>
        {name}
    </span>
);

export default Label;