import AttractionsPage from '@components/pages/AttractionsPage/AttractionsPage';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <AttractionsPage />
    </QueryClientProvider>
);

export default App;